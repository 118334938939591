<template>
  <div class="contact">
    <div class="container">
      <div class="mail">
        <h1>Contact Me</h1>
        <div class="mail-address">
          <img src="../assets/images/about/icons/mail.png" alt="mail" />
          <span>kentforth@mail.ru</span>
        </div>
        <div class="telegram">
          Telegram -
          <span>kentforth</span>
        </div>
        <div class="linked">
          <a
            href="https://www.linkedin.com/in/konstantin-rusanov/"
            target="_blank"
            >LinkedIn</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contact"
};
</script>

<style scoped>
h1 {
  color: var(--light);
  justify-self: end;
}

.mail {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mail-address,
.linked,
.telegram {
  display: flex;
  align-items: center;
  justify-self: end;
}

.mail-address img {
  width: 30px;
}

.mail-address span,
.telegram,
.linked {
  color: var(--secondary);
  font-size: 2rem;
  margin-left: 10px;
}

.linked a {
  color: var(--secondary);
}

p {
  margin-top: 1em;
  font-size: 2rem;
  text-align: left;
}

.form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 80%;
  grid-gap: 2em;
  justify-content: center;
  margin: 2.5em auto;
}

.form-input {
  display: flex;
  flex-direction: column;
}

label {
  color: var(--light);
  text-align: left;
  font-size: 2rem;
}

input {
  font-size: 2rem;
}

input,
textarea {
  border-radius: 7px;
  border: 4px solid var(--secondary);
  background-color: transparent;
  margin-top: 10px;
  color: var(--accent);
  padding: 10px;
}

input:focus,
textarea:focus {
  outline: none;
  border: 4px solid var(--light);
}

textarea {
  resize: none;
  font-size: 1.5rem;
}

.form-input textarea {
  width: 100%;
}

.form-input:nth-child(3) {
  grid-column-start: 1;
  grid-column-end: 3;
  justify-self: start;
  width: 100%;
}

.btn {
  grid-column: 1/3;
  font-size: 2rem;
  padding: 10px 20px;
  width: 20%;
  justify-self: center;
  border-radius: 50px;
  cursor: pointer;
  outline: none;
  border: none;
  background-color: var(--accent);
  color: var(--dark);
  font-weight: 600;
  font-family: "Rubik", sans-serif;
}

.btn:active {
  background-color: #c0b326;
}

.is-invalid {
  border: 4px solid orangered;
}

.invalid-feedback {
  color: orangered;
  text-align: left;
  padding-top: 10px;
  font-family: "Helvetica", sans-serif;
  letter-spacing: 1px;
  font-size: 1.2rem;
}

/*MEDIA QUERIES*/
@media screen and (max-width: 768px) {
  .container {
    max-width: 90%;
  }

  input {
    width: 100%;
  }

  .form {
    max-width: 100%;
  }

  .form-input {
    width: 100%;
  }

  .mail-address span {
    font-size: 1.2rem;
  }

  .mail-address img {
    width: 18px;
  }

  .mail {
    grid-gap: 30px;
  }

  .mail h1 {
    justify-self: end;
  }

  .btn {
    width: 45%;
  }
}

@media screen and (max-width: 480px) {
  .mail {
    grid-template-columns: 1fr;
    justify-content: center;
    justify-items: center;
    grid-gap: 0;
  }

  .mail h1 {
    justify-self: center;
  }

  p {
    font-size: 1.5rem;
  }

  .mail-address {
    justify-self: center;
  }

  .mail-address span {
    font-size: 1.5rem;
  }

  .mail-address img {
    width: 20px;
  }

  label {
    font-size: 1.5rem;
  }

  input {
    font-size: 1.5rem;
  }

  .form {
    display: flex;
    flex-direction: column;
  }

  .btn {
    margin: 0 auto;
  }
}

@media screen and (max-width: 320px) {
  .mail h1 {
    font-size: 1.5rem;
  }

  .mail span {
    font-size: 1.2rem;
  }

  .mail-address img {
    width: 15px;
  }

  p {
    font-size: 1.2rem;
  }

  label,
  input {
    font-size: 1.3rem;
  }

  .invalid-feedback {
    font-size: 0.8rem;
  }

  .btn {
    width: 70%;
  }
}
</style>
