<template>
  <div class="projects container">
    <h1>My Projects</h1>
    <div
      class="projects-all"
      v-for="(project, projectIndex) in projects"
      :key="projectIndex"
    >
      <hr class="line" />
      <div class="project-item">
        <div class="project-item__image">
          <img
            :src="project.image"
            :alt="project.title"
            class="project-image"
          />
          <div class="image__icons">
            <img :src="project.icon1" :alt="project.icon2" />
            <img :src="project.icon2" :alt="project.icon2" />
            <img
              :src="project.icon3"
              :alt="project.icon3"
              v-if="project.hasIcon3"
            />
            <img
              :src="project.icon4"
              :alt="project.icon4"
              v-if="project.hasIcon4"
            />
            <img
              :src="project.icon5"
              :alt="project.icon5"
              v-if="project.hasIcon5"
            />
          </div>
        </div>
        <div class="project-item__content">
          <h2>{{ project.title }}</h2>
          <p>
            {{ project.description }}
          </p>
          <div class="content-links">
            <a :href="project.viewLink" target="_blank">
              <img
                src="../assets/images/projects/icons/buttons/eye.svg"
                alt="eye"
              />
              <span>View</span>
            </a>
            <a
              :href="project.githubLink"
              target="_blank"
              v-if="project.hasGithubLink"
            >
              <img
                src="../assets/images/projects/icons/buttons/github.svg"
                alt="eye"
              />
              <span>Github</span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <hr class="line" />
  </div>
</template>

<script>
export default {
  name: "Projects",
  data() {
    return {
      projects: [
        {
          image: require("../assets/images/projects/uteka.webp"),
          viewLink: "https://uteka.ru/",
          title: "Ютека",
          description:
            "Developing this platform as a frontend developer. It helps people to find medical supplies in near pharmacies",

          icon1: require("../assets/images/projects/icons/nuxt.png"),
          icon2: require("../assets/images/projects/icons/typescript.png"),
          icon3: require("../assets/images/projects/icons/pinia.png"),
          icon4: "",
          icon5: "",
          hasIcon3: true,
          hasIcon4: false,
          hasIcon5: false,
          hasGithubLink: false
        },
        {
          image: require("../assets/images/projects/invest-gate.webp"),
          viewLink: "https://investgate.ru/",
          title: "InvestGate",
          description:
            "Developed this platform as frontend developer. It help people to invest money in projects and help borrowers to make loans for their own financial projects",

          icon1: require("../assets/images/projects/icons/javascript.png"),
          icon2: require("../assets/images/projects/icons/nuxt.png"),
          icon3: require("../assets/images/projects/icons/typescript.png"),
          icon4: "",
          icon5: "",
          hasIcon3: true,
          hasIcon4: false,
          hasIcon5: false,
          hasGithubLink: false
        },
        /*{
          image: require("../assets/images/projects/control-surface.webp"),
          githubLink: "https://github.com/kentforth/Control-Surface",
          viewLink: "https://control-surface.ml/",
          title: "Control Surface",
          description:
            "The website helps users to get arduino sketches to build their own custom midi controller",

          icon1: require("../assets/images/projects/icons/javascript.png"),
          icon2: require("../assets/images/projects/icons/vue.png"),
          icon3: require("../assets/images/projects/icons/firebase.jpg"),
          icon4: "",
          icon5: "",
          hasIcon3: true,
          hasIcon4: false,
          hasIcon5: false,
          hasGithubLink: true
        },*/
        {
          image: require("../assets/images/projects/pusher.webp"),
          githubLink: "https://github.com/kentforth/Pusher",
          viewLink: "https://pushme.netlify.app/",
          title: "Pusher",
          description:
            "Chat Application that helps people to send messages each other",

          icon1: require("../assets/images/projects/icons/javascript.png"),
          icon2: require("../assets/images/projects/icons/vue.png"),
          icon3: require("../assets/images/projects/icons/firebase.jpg"),
          icon4: "",
          icon5: "",
          hasIcon3: true,
          hasIcon4: false,
          hasIcon5: false,
          hasGithubLink: true
        },
        {
          image: require("../assets/images/projects/blizzard-stats.jpg"),
          githubLink: "https://github.com/kentforth/Blizzard-client",
          viewLink: "https://blizzard-app.netlify.app/",
          title: "Blizzard Stats",
          description:
            "Web App that shows statistics of Blizzard games. It is demonstration of Web Sockets. Data is fake and generated by the server",

          icon1: require("../assets/images/projects/icons/typescript.png"),
          icon2: require("../assets/images/projects/icons/vue.png"),
          icon3: require("../assets/images/projects/icons/node.png"),
          icon4: require("../assets/images/projects/icons/websockets.png"),
          icon5: "",
          hasIcon3: true,
          hasIcon4: true,
          hasIcon5: false,
          hasGithubLink: true
        },
        {
          image: require("../assets/images/projects/trail-notes.jpg"),
          githubLink: "https://github.com/kentforth/Trail-Notes",
          viewLink: "https://naughty-poitras-bd2c86.netlify.app/",
          title: "Trail Notes",
          description:
            "This is a website for photographers who wants to visit most beautiful places. The author of the website helps photographers in their adventure and offer different services for their needs. Like personal experience of trails and GPS Coordinates",

          icon1: require("../assets/images/projects/icons/javascript.png"),
          icon2: require("../assets/images/projects/icons/vue.png"),
          icon4: "",
          icon5: "",
          hasIcon3: false,
          hasIcon4: false,
          hasIcon5: false,
          hasGithubLink: true
        },
        {
          image: require("../assets/images/projects/crud-test.png"),
          githubLink: "https://github.com/kentforth/crud-test",
          viewLink: "https://kentforth-crud-test.netlify.app/",
          title: "Crud-test",
          description:
            "CRUD website to get, delete, update posts. JSON placeholder api is used for API requests",

          icon1: require("../assets/images/projects/icons/vue.png"),
          icon2: require("../assets/images/projects/icons/typescript.png"),
          icon3: require("../assets/images/projects/icons/pinia.png"),
          icon4: "",
          icon5: "",
          hasIcon3: true,
          hasIcon4: false,
          hasIcon5: false,
          hasGithubLink: true
        },
        {
          image: require("../assets/images/projects/appointments.jpg"),
          githubLink: "https://github.com/kentforth/Appointment",
          viewLink: "https://appointment-task.netlify.app/make-appointments",
          title: "Appointment",
          description:
            "This application is useful when you create and manage tasks.No backend is used. It is " +
            "only client-side app. Storing and managing data is used with help of Vuex and local storage",

          icon1: require("../assets/images/projects/icons/javascript.png"),
          icon2: require("../assets/images/projects/icons/vue.png"),
          icon3: "",
          icon4: "",
          icon5: "",
          hasIcon3: false,
          hasIcon4: false,
          hasIcon5: false,
          hasGithubLink: true
        }
      ]
    };
  }
};
</script>

<style scoped>
h1 {
  margin: 0;
  color: var(--light);
  transform: translateY(1em);
  border-top: 2px solid var(--secondary);
  padding-top: 1em;
}

.projects-all {
  margin-top: 4em;
}

.project-item {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: start;
  grid-gap: 3em;
  margin-bottom: 2em;
}

.project-image {
  width: 600px;
  height: 300px;
  object-fit: cover;
  object-position: center;
  box-shadow: 0 0 30px 7px rgba(0, 0, 0, 0.4);
}

.image__icons {
  width: 100%;
  margin: 2em auto 0 auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.image__icons img {
  margin-right: 40px;
}

.project-item__image {
  width: 100%;
  height: 100%;
}

.project-item__content {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: space-between;
}

.project-item__content h2 {
  color: var(--accent);
  font-weight: 400;
  text-align: left;
  font-size: 2rem;
  margin: 0;
}

.project-item__content p {
  text-align: left;
  font-size: 1.5rem;
}

.content-links {
  font-size: 2rem;
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 24px;
  margin: auto 0 0;
  justify-self: end;
}

.content-links a {
  background-color: var(--accent);
  padding: 16px 24px;
  font-weight: 500;
  color: var(--dark);
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 50px;
}

.content-links a:nth-child(2) {
  background-color: var(--accent-secondary);
}

.content-links a > span {
  margin-left: 10px;
}

.content-links img {
  width: 40px;
  object-fit: cover;
  object-position: center;
}

.line {
  width: 100%;
  margin: 3em auto;
  border: 0;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(248, 248, 248, 0),
    rgba(248, 248, 248, 0.75),
    rgba(248, 248, 248, 0)
  );
}

/*MEDIA QUERIES*/
@media screen and (max-width: 1024px) {
  .container {
    max-width: 90%;
  }

  .project-item {
    grid-gap: 2em;
    grid-template-columns: 1fr;
  }

  .content-links {
    display: flex;
    width: 100%;
  }

  .image__icons {
    width: 15%;
  }
}

@media screen and (max-width: 600px) {
  .container {
    padding-bottom: 24px;
  }

  h1 {
    font-size: 1.5rem;
    margin-bottom: 2em;
  }

  h2 {
    font-size: 1.5rem !important;
  }

  .project-image {
    width: 300px;
    height: 200px;
  }

  .projects-all {
    margin-top: 2em;
  }

  .project-item {
    grid-template-columns: 1fr;
    margin-bottom: 0;
  }

  .image__icons {
    width: 100%;

    img {
      height: 50px;
    }
  }

  .project-item__content h2 {
    text-align: center;
  }

  .project-item__content p {
    font-size: 1.2rem;
  }

  .content-links {
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin: 0 auto 0;
  }

  .content-links a {
    padding: 0.3em 0.5em;
    font-size: 1.2rem;
  }

  .content-links img {
    width: 20%;
  }

  .image__icons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
    justify-content: center;
    justify-items: center;

    img {
      margin-right: 0;
    }
  }

  .line {
    margin: 0 auto 2em auto;
  }

  .line:last-child {
    display: none;
  }
}
</style>
